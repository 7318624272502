import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import { HvacCalcForm } from 'components/Tools/HvacCalculator/Form';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import useIsClient from 'hooks/useIsClient';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';
import BookmarkContext from 'utils/BookmarkContext';

const HvacCalcTemplate = (props) => {
  const isClient = useIsClient();
  const title = 'HVAC Load Calculator';
  const data =
    props.path === '/tools/hvac-load-calculator-1' ? props.data.testTool : props.data.tool;
  const breadcrumbs = createToolsBreadcrumbs(title);
  const pathUrl = props.location;

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <HvacCalcForm pathUrl={pathUrl} gatedForm={data.gatedForm} />
        {isClient && <DescriptionBlock data={data.description} />}
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default HvacCalcTemplate;

export const HvacCalcTemplateQuery = graphql`
  query HvacCalcTemplateQuery {
    tool: contentfulTool(internalName: { eq: "HVAC Load Calculator" }) {
      ...Tool
    }
    testTool: contentfulTool(internalName: { eq: "HVAC Load Calculator - Ad Banner Test" }) {
      ...Tool
    }
  }
`;
